import React from "react"
import SEO from "../../components/seo"
import EventHeader from "../../components/EventHeader"
import Footer from "../../components/Footer"

const SpringClean2024 = () => {
  return (
    <>
      <EventHeader imageSrc="/images/3.jpg" halfHeight />
      <SEO title="CleanUp - Spring Clean 2023" />
      <div class="relative py-16 bg-white">
        <div class="relative px-4 py-20 sm:px-6 lg:px max-w-6xl mx-auto -mt-32 bg-white">
          <div class="text-lg mx-auto leading-tight">
            <h1>
              <span class="mt-2 block text-5xl text-center font-serif  font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                CleanUp
              </span>
              <span class="mt-5 mb-10 block text-5xl italic text-center font-serif font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                ‘Spring Clean’ Weekend
              </span>
            </h1>
            <h2 class="mt-0 block text-base text-3xl text-center text-green-600 font-bold tracking-widest uppercase mb-5">
              6 & 7th April 2024
            </h2>
          </div>
          <div class="my-12 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Do you love where you live? Sevenoaks District residents are being
            invited to roll up their sleeves this spring and help tackle litter.
            Grab a bag and pick up some litter to be part of the weekend. 5
            minutes or 5 hours or 5 bags, every little helps. There are no
            organised groups going out, just a community each taking a tiny bit
            of responsibility to clean up after the ‘losers’ (PG 13 version) who
            litter.
          </div>
          <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <h4>What to do with your collected litter</h4>
            <p>
              What to do with your collected litter Litter picking heroes are
              kindly requested to leave their bags of litter next to any
              Sevenoaks District Council bin from where it will be collected as
              soon as possible. For any large items, or specific collection
              requests, please email direct.services@sevenoaks.gov.uk.
            </p>

            <dt class="mt-20 text-sm font-medium text-gray-500">Attachments</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg
                      class="flex-shrink-0 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      CleanUp Safely guidelines
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a
                      href="/files/CleanUp_Safely.pdf"
                      class="font-medium text-blue-600 hover:text-blue-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SpringClean2024
